import React from "react"
import styled from "@emotion/styled"
import { ALAKERTA_DARK_BACKGROUND } from "../util/constants"

const StoryStyle = styled.div`
  background: ${ALAKERTA_DARK_BACKGROUND};
  min-height: 80vh;
  color: red;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`

const Story = () => {
  return (
    <StoryStyle>
      <h1>story</h1>
    </StoryStyle>
  )
}

export default Story
