import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import Story from "../components/Story"

const StoryPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "menu_item.story" })} />
      <Story />
    </Layout>
  )
}

export default StoryPage
